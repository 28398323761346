import React from "react"
import {
	// FaMapMarkerAlt,
	FaInstagram,
	FaFacebook,
	// FaRegClock,
} from "react-icons/fa"
// import { MdPhone, MdEmail } from "react-icons/md"
import { FiGlobe } from "react-icons/fi"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FormLink from "../../components/formLink"

const MeetingDetail = ({ label, description, children }) => (
	<div className="grid sm:grid-cols-4 sm:gap-3 mb-3 sm:mb-0 max-w-md mx-6 sm:mx-auto">
		<span>
			<b>{label}</b>
		</span>
		<span className="sm:col-span-3">{children || description}</span>
	</div>
)

const LupusWaCommunity = () => (
	<Layout>
		<SEO title="Support groups for lupus patients in Western Australia - Lupus WA Community" />
		<h1>Lupus WA Community</h1>
		<p>
			Lupus WA provides face-to-face, telephone and email support to lupus
			patients and their families. We also facilitate support groups.
		</p>
		<h2>You are not alone</h2>
		<p>
			Support groups are a great way to meet other people impacted by lupus in a
			friendly, safe and supportive environment.
		</p>
		<p>
			Don’t suffer in silence. Come along to our monthly support group meeting
			where you can share your experience, receive encouragement and learn from
			our collective “lived experiences”.
		</p>
		<MeetingDetail
			label="WHEN:"
			description="The first Wednesday of each month"
		/>
		<MeetingDetail label="TIME:" description="1:00pm – 2:30pm" />
		<MeetingDetail label="VENUE:">
			<a
				href="https://g.page/ArthritisWA"
				rel="noopener noreferrer"
				target="_blank"
				className="link"
			>
				<span>Arthritis and Osteoporosis WA</span>
				<br />
				<span>17 Lemnos Street, Shenton Park, WA</span>
			</a>
		</MeetingDetail>
		<MeetingDetail label="CONTACT:">
			<a href="tel:0893882199" className="link">
				(08) 9388 2199
			</a>
			<br />
			<a className="link" href="mailto:lupuswa@arthritiswa.org.au">
				lupuswa@arthritiswa.org.au
			</a>
		</MeetingDetail>
		<p>
			For other ways to be supported by the Lupus WA community visit our
			Website, Facebook and Instagram pages.
		</p>
		<div className="rounded grid grid-cols-1 gap-2 shadow p-4 my-8 text-purple-900 bg-purple-200">
			{/* <a
				href="https://g.page/ArthritisWA?share"
				rel="noopener noreferrer"
				target="_blank"
				className="block flex"
			>
				<FaMapMarkerAlt
					className="mr-6 flex-grow-0 flex-shrink-0 my-auto"
					size="21px"
				/>
				<span>Arthritis Foundation, 17 Lemnos Street Shenton Park, WA</span>
			</a> */}
			{/* <div className="flex">
				<FaRegClock
					className="mr-6 flex-grow-0 flex-shrink-0 my-auto"
					size="21px"
				/>
				<span className="flex-grow">
					1:00 pm - 2:30 pm on the first Wednesday of each month{" "}
				</span>
			</div> */}
			<a
				href="https://www.lupuswa.com.au"
				rel="noopener noreferrer"
				target="_blank"
				className="block flex"
			>
				<FiGlobe
					className="mr-6 flex-grow-0 flex-shrink-0 my-auto"
					size="21px"
				/>
				<span className="underline">www.lupuswa.com.au</span>
			</a>
			{/* <a href="tel:0893882199" className="block flex">
				<MdPhone
					className="mr-6 flex-grow-0 flex-shrink-0 my-auto"
					size="21px"
				/>
				<span className="underline">(08) 9388 2199</span>
			</a> */}
			{/* <a href="mailto:lupuswa@arthritiswa.org.au" className="block flex">
				<MdEmail
					className="mr-6 flex-grow-0 flex-shrink-0 my-auto"
					size="21px"
				/>
				<span className="underline">lupuswa@arthritiswa.org.au</span>
			</a> */}
			<a
				href="https://www.facebook.com/LupusWANew/"
				rel="noopener noreferrer"
				target="_blank"
				className="block flex"
			>
				<FaFacebook
					className="mr-6 flex-grow-0 flex-shrink-0 my-auto"
					size="21px"
				/>
				<span className="underline">@lupuswanew</span>
			</a>
			<a
				href="https://www.instagram.com/lupuswise/"
				rel="noopener noreferrer"
				target="_blank"
				className="block flex"
			>
				<FaInstagram
					className="mr-6 flex-grow-0 flex-shrink-0 my-auto"
					size="21px"
				/>
				<span className="underline">@lupuswise</span>
			</a>
		</div>
		<FormLink text="If you have information on any lupus support groups that would be useful to others, " />
	</Layout>
)

export default LupusWaCommunity
